@font-face {
  font-family: "Soleil";
  font-display: block;
  src: url("./assets/fonts/SoleilRegular.otf") format("opentype");
}

@font-face {
  font-family: "SharpSans-extra-bold";
  font-display: block;
  src: url("./assets/fonts/SharpSans-No1-Extrabold.otf") format("opentype");
}

@font-face {
  font-family: "Soleilsemibold";
  font-display: block;
  src: url("./assets/fonts/SoleilBook.otf") format("opentype");
}

@font-face {
  font-family: "SharpSans-bold";
  font-display: swap;
  src: url("./assets/fonts/SharpSans-No1-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SharpSans-book";
  font-display: swap;
  src: url("./assets/fonts/SharpSans-No1-Book.otf") format("opentype");
}

@font-face {
  font-family: "SharpSans-semi-bold";
  font-display: swap;
  src: url("./assets/fonts/SharpSans-No1-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Soleilextrabold";
  font-display: swap;
  src: url("./assets/fonts/SoleilBold.otf") format("opentype");
}

.font-normal {
  font: normal normal normal 15px/19px Soleil !important;
  letter-spacing: -0.24px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.font-weight-light {
  font-weight: 300;
}

.font-important {
  font: normal normal normal 18px/26px Soleil;
  color: var(--color-semidark);
}

.heading-1 {
  font: normal normal 600 35px/44px Soleil;
}

.heading-2 {
  font: normal normal 800 43px/59px SharpSans-extra-bold;
  letter-spacing: 2.58px;
}

.heading-3 {
  font: normal normal normal 25px/30px SharpSans-semi-bold;
  letter-spacing: 1.25px;
}

.uppercase {
  text-transform: uppercase;
}
