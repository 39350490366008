:root {
  --color-light: #fafafa;
  --color-light2: #e2e2e2;
  --color-semilight: #aaaaaa;
  --color-blue: #deeef0;
  --color-semidark: #6f6f6f;
  --color-dark: #434343;
  --color-red: #d35551;
  --color-lightred: #FAE0DF;
  --color-grey: #E7EBEC;
  --color-lightgrey: #DBDBDB;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lmd: 820px,
  lg: 992px,
  xl: 1200px,
  xxl: 1320px
);

.text-semidark {
  color: var(--color-semidark) !important;
}

.text-dark {
  color: var(--color-dark) !important;
}

.text-red {
  color: var(--color-red) !important;
}

.text-white {
  color: white !important;
}

.link {
  color: var(--color-red) !important;
  text-decoration: underline;
  font: normal normal 600 18px/26px Soleil;

  &:hover {
    text-decoration: none;
  }
}

.bg-blue {
  background-color: var(--color-blue);
}

.bg-light {
  background-color: var(--color-light);
}

.bg-red {
  background-color: var(--color-red)
}

.bg-white {
  background-color: white;;
}

.bg-grey {
  background-color: var(--color-grey);
}

.bg-lightgrey {
  background-color: var(--color-lightgrey);
}
