@use 'fonts';
@use 'variables';

@import '/node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '/node_modules/bootstrap53/scss/mixins/breakpoints';
@import '/node_modules/bootstrap53/scss/functions';
@import '/node_modules/bootstrap53/scss/variables';
@import '/node_modules/bootstrap53/scss/mixins';

.clear {
  clear: both;
}

.object-contain {
  object-fit: contain;
}

.invalid-feedback {
  display: block !important;
  font-family: 'Soleil';
  font-size: 13px;
}

.invisible {
  visibility: hidden !important;
}

.micromodal-slide {
  z-index: 2147483647 !important;
}

.leaflet-pane,
.leaflet-control-container,
.leaflet-container {
  z-index: 0 !important;
}

.w-40 {
  width: 40% !important;
}

.btn-secondary {
  background-color: var(--color-semidark);
}

.btn-danger {
  background-color: var(--color-red);
}

html,
body {
  padding: 0px !important;
  margin: 0px !important;
  /* overflow-x: hidden; */
  box-sizing: border-box;
}

.sharpsans-book {
  font-family: SharpSans-book !important;
}

.sharpsans-bold {
  font-family: SharpSans-bold !important;
}

.sharpsans-semi-bold {
  font-family: SharpSans-semi-bold !important;
}

.sharpsans-extra-bold {
  font-family: SharpSans-extra-bold !important;
}

.soleil {
  font-family: Soleil !important;
}

.text-dark-grey {
  color: #555555 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fonts-5 {
  letter-spacing: 0.05em;
}

.fonts-6 {
  letter-spacing: 0.06em;
}

.fonts-7 {
  letter-spacing: 0.07em;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.overflow-y {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.overflow-x {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.overflow {
  overflow: auto !important;
  overflow: auto !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

.text-link {
  cursor: pointer;
}

.text-fluid {
  font-size: 10px;
}

.text-small {
  font-size: 90%;
}

.text-xs {
  font-size: 12px !important;
}

.text-xxs {
  font-size: 9px !important;
}

.text-xxxs {
  font-size: 5px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-md {
  font-size: 24px !important;
}

.text-slmd {
  font-size: 20px !important;
}

.text-ssmd {
  font-size: 18px !important;
}

.text-smd {
  font-size: 16px !important;
}

.text-xmd {
  font-size: 28px !important;
}

.text-lg {
  font-size: 30px !important;
}

.text-xlg {
  font-size: 36px !important;
}

.text-xxl {
  font-size: 42px !important;
}

.text-xxlg {
  font-size: 46px !important;
}

.text-82 {
  font-size: 82px !important;
}

.border-12 {
  border-radius: 12px !important;
  border-width: 0px !important;
}

.border-0 {
  border-radius: 0px !important;
}

.bold {
  font-weight: 1000 !important;
}

.bold-lg {
  font-weight: 1600 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold-sm {
  font-weight: 450 !important;
}

.underlay {
  z-index: -1 !important;
}

.overlay {
  z-index: 999 !important;
}

.text-red {
  color: #d35551 !important;
}

.bg-red {
  background-color: #d35551 !important;
}

.text-grey {
  color: #797979;
}

.text-light-grey {
  color: #707070;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.posfixed {
  position: fixed;
}

.posrelative {
  position: relative;
}

.col-6 :not(.col-12).info-popular {
  position: fixed;
}

a.reset-calendar.pull-right {
  font-weight: bold;
  color: #666 !important;
  margin-bottom: 1em;
  margin-right: 1em;
  text-decoration: underline;
  cursor: pointer;
}

.transfers .autocomplete-container,
.transfers .input-container {
  border-radius: 28px;
  height: 38px;
  border: none !important;
  box-shadow: none !important;
}

.transfers .autocomplete-container .input-container input {
  height: 38px;
  line-height: initial;
  background-color: transparent;
  text-align: center;
}

/* Material cutout for mat-checkbox */

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: #000;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary
  .mat-ripple-element {
  background: #3f51b5;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: #ff4081;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#mobsortby:not(.collapsed) > #sortby {
  display: block !important;
}

/* V2 hacks */

// main {
//   overflow: visible !important;
// }

main {
  min-height: 100%;
}

.ngb-dp-month {
  margin-top: -1.8rem;

  &:nth-of-type(2) {
    display: none;
  }
}
ngb-popover-window {
  opacity: 1 !important;
}

.popover.show {
  z-index: 999999 !important;
}

.popover-body {
  background-color: var(--color-blue);
}

ngb-datepicker {
  .ngb-dp-navigation-select {
    display: none;
  }

  & * {
    background-color: var(--color-blue) !important;
  }

  .ngb-dp-day {
    font-weight: 600;

    .range {
      display: block;
      width: 30px;
      height: 30px;
      background-color: white !important;
      margin-top: -0.5rem;
      margin-left: -7px;
      overflow: hidden;
      color: var(--color-red);
      border-radius: 50%;
      border: 1px solid var(--color-red);
      line-height: 29px;
    }

    &.hidden,
    &.disabled {
      color: #aaa;
      pointer-events: none;
    }
  }

  .ngb-dp-weekday {
    color: black;
    font-weight: 600;
  }

  .ngb-dp-day,
  .ngb-dp-weekday {
    background-color: var(--color-blue);
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-family: 'Soleil';
    font-size: 14px;
    margin: 1.3rem;
  }

  .ngb-dp-arrow,
  .ngb-dp-arrow-btn,
  .ngb-dp-navigation-chevron {
    color: var(--color-dark);
    font-size: 14px;
    outline: none !important;
    outline-color: transparent !important;
    box-shadow: none !important;
  }

  .ngb-dp-month-name {
    font-size: 15px;
    color: var(--color-dark);
    font-family: 'Soleil';
  }

  ngb-datepicker-month {
    margin-top: 1.5rem;
  }

  ngb-datepicker-navigation {
    * {
      font-family: 'Soleil';
      font-size: 15px;
    }
  }

  *[aria-label^='Saturday'] {
    &:not(:has(.range)) {
      white-space: nowrap;

      &::after {
        content: '*';
      }
    }
  }
}

#gallery-modal {
  .carousel-inner {
    width: 100vw;
    height: 90vh;

    .carousel-item {
      float: none;
      text-align: center;
      height: 90vh;
      width: 100vw;
      transition: none;
    }
    & > .active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modal__close {
  background: transparent;
  border: 0;

  &:focus {
    border: none;
    box-shadow: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
  }
}

.modal__header .modal__close:before {
  content: '\2715';
  font-size: 20px;
  font-weight: bold;
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font: normal normal normal 15px/19px Soleil;
  letter-spacing: 0px;
  color: var(--color-dark);

  & > * {
    line-height: 1.8;
  }
}

.transparent {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

@include media-breakpoint-only(xxl) {
  main {
    overflow: visible !important;
  }

  body {
    .whereBox,
    .whenBox,
    .occupancyBox {
      &.fade {
        opacity: 1 !important;
        max-width: 710px;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

@include media-breakpoint-down(xxl) {
  div#live-chat-widget {
    bottom: 50px !important;
  }

  body {
    .popover {
      max-width: 90%;
    }
  }
}

@media (max-width: 380px) {
  .ngb-dp-day,
  .ngb-dp-weekday {
    margin: 0.7rem !important;
  }
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.search .woot-widget-bubble,
.widget-bubble {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 820px) {
  .search .woot-widget-bubble {
    margin-bottom: 48px !important;
  }
}

.user-custom-phone-control {
  .iti {
    width: 100%;
  }
  .dropdown-menu {
    font-size: 14px !important;
  }
  .custom-phone-control {
    background: #f0f0f0;
    color: var(--color-semidark);
    font: 15px/19px Soleil;
    letter-spacing: -0.24px;
    border: none;
    box-shadow: none;
    height: 52px;

    &.invalid {
      border: 1px solid red;
    }
  }
}

.stripe-error.alert-danger {
  font-size: 16px;
  background: var(--color-red) !important;
  color: var(--color-light) !important;
  .material-symbols-outlined {
    cursor: pointer;
  }
}